import React from 'react'
import PropTypes from 'prop-types'
import NotificationRecipientTable from '@components/notificationrecipienttable'

export const frontmatter = {
  title: 'Umfragebenachrichtigungen',
}

function SurveyNotificationPage() {
  return <NotificationRecipientTable />
}

SurveyNotificationPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SurveyNotificationPage
